<template>
  <div id="tender-list">
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-payment-create'"
        :go-to-link-id="`${$route.params.id}`"
        :title="'Payment list'"
        :additional-title="company ? ` - ${company.name}` : ''"
        :button-text="'Create'"
        :show-action-button="false"
        :link-button-color="'success'"
      />
      <v-data-table
        v-model="selectedRows"
        dense
        :headers="tableColumns"
        :items="paymentListTable"
        :items-per-page="15"
        :options.sync="options"
        :server-items-length="totalPayments"
        :loading="loading"
        :no-results-text="$t('noResults')"
        :no-data-text="$t('noResults')"
        :footer-props="{
          'items-per-page-text': '#',
          'items-per-page-options': [10, 20, 50, 100],
          'page-text': `{0}-{1} ${$t('of')} {2}`
        }"
      >
        <template #[`item.value`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              <router-link
                :to="{ name : 'admin-payment-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ (+item.value * 1.2).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.comment`]="{item}">
          <div
            class="d-flex align-center"
          >
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-payment-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.comment }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'admin-payment-edit',params:{id:item.id}}"
                link
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('Edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deletePayment(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('Delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restorePayment(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('Restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const selectedRows = []
    const paymentListTable = []
    const totalPayments = 0
    const loading = true
    const options = {}
    const company = null

    const tableColumns = [
      {
        text: this.$t('Value'),
        value: 'value',
        sortable: true,
      },
      {
        text: this.$t('Comment'),
        value: 'comment',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    return {
      company,
      options,
      loading,
      tableColumns,
      paymentListTable,
      selectedRows,
      totalPayments,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
        this.getCompany()
      },
      deep: true,
    },
  },

  methods: {
    getDataFromApi() {
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'id'
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : 'true'
      const search = this.searchQuery ?? ''
      const status = this.statusFilter ?? ''
      const type = this.typeFilter ?? ''
      axiosIns.get(`/admin/companies/${this.$route.params.id}/payments?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&status=${status}&type=${type}`)
        .then(res => {
          this.totalPayments = res.data.payments.total
          this.paymentListTable = res.data.payments.data
          this.loading = false
        })
        .catch()
    },
    getCompany() {
      axiosIns.get(`/admin/companies/${this.$route.params.id}`).then(res => { this.company = res.data.company })
    },
    deletePayment(id) {
      axiosIns.delete(`/admin/payments/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restorePayment(id) {
      axiosIns.put(`/admin/payments/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>
